import Vue from 'vue';
import VueMoment from 'vue-moment';
import moment from 'moment';
import store from '@/store';  // Import the Vuex store

import 'moment/locale/en-sg';
import 'moment/locale/zh-cn';

moment.locale(store.state.locale);  // Set the initial locale

// Watch for changes in the locale and update moment's locale accordingly
store.watch(
  (state) => state.locale,
  (newLocale) => {
    moment.locale(newLocale);
  }
);

Vue.use(VueMoment, { moment });