import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

var firebaseConfig = {
  apiKey: "AIzaSyB6GAQScbmEAAcLE6IOhKgNf7f6g57Lpg0",
  authDomain: "test-de6a4.firebaseapp.com",
  projectId: "test-de6a4",
  storageBucket: "test-de6a4.appspot.com",
  messagingSenderId: "25934867359",
  appId: "1:25934867359:web:078340cb0641e0cfe02baa",
  measurementId: "G-VFYWHXVWQ1"
}

const app = initializeApp(firebaseConfig);

const a = getMessaging(app);

export default a
