import Vue from 'vue';

import {notification} from 'ant-design-vue';
import 'ant-design-vue/lib/notification/style/css';
Vue.prototype.$notification = notification;
Vue.mixin({
  methods: {
    notifySuccess(description, title = this.$t('SaveSuccess')) {
      this.$notification.success({
        message: title,
        description: description
      });
    },
    notifyError(description, title = this.$t('ErrorOccurred')) {
      this.$notification.error({
        message: title,
        description: description
      });
    },
    notifyInfo(description, title = this.$t('processing')) {
      this.$notification.info({
        message: title,
        description: description
      });
    },
  },
});

import {message} from 'ant-design-vue';
import 'ant-design-vue/lib/message/style/css';
Vue.prototype.$message = message;

import {Tooltip} from 'ant-design-vue';
import 'ant-design-vue/lib/tooltip/style/css';
Vue.component(Tooltip.name, Tooltip);

import {Button} from 'ant-design-vue';
import 'ant-design-vue/lib/button/style/index.css';
Vue.component(Button.name, Button);
Vue.component(Button.Group.name, Button.Group);

import {Popconfirm} from 'ant-design-vue';
import 'ant-design-vue/lib/popconfirm/style/css';
Vue.component(Popconfirm.name, Popconfirm);

import {Modal} from 'ant-design-vue';
import 'ant-design-vue/lib/modal/style/css';
Vue.component(Modal.name, Modal);
Vue.use(Modal);

import {Tabs} from 'ant-design-vue';
import 'ant-design-vue/lib/tabs/style/css';
Vue.component(Tabs.name, Tabs);
Vue.component(Tabs.TabPane.name, Tabs.TabPane);

import {Icon} from 'ant-design-vue';
import 'ant-design-vue/lib/icon/style/css';
Vue.component(Icon.name, Icon);

import {Alert} from 'ant-design-vue';
import 'ant-design-vue/lib/alert/style/css';
Vue.component(Alert.name, Alert);

import {Empty} from 'ant-design-vue';
import 'ant-design-vue/lib/empty/style/css';
Vue.component(Empty.name, Empty);

import {Card} from 'ant-design-vue';
import 'ant-design-vue/lib/card/style/css';
Vue.component(Card.name, Card);

import {Badge} from 'ant-design-vue';
import 'ant-design-vue/lib/badge/style/css';
Vue.component(Badge.name, Badge);

import {Tag} from 'ant-design-vue';
import 'ant-design-vue/lib/tag/style/css';
Vue.component(Tag.name, Tag);

import {Input, InputNumber} from 'ant-design-vue';
import 'ant-design-vue/lib/input/style/css';
import 'ant-design-vue/lib/input-number/style/css';
Vue.component(Input.name, Input);
Vue.component(InputNumber.name, InputNumber);
Vue.component(Input.TextArea.name, Input.TextArea);
Vue.component(Input.Search.name, Input.Search);

import {Checkbox} from 'ant-design-vue';
import 'ant-design-vue/lib/checkbox/style/css';
Vue.component(Checkbox.name, Checkbox);

import {Form} from 'ant-design-vue';
import 'ant-design-vue/lib/form/style/css';
Vue.component(Form.name, Form);
Vue.component(Form.Item.name, Form.Item);

import {Table} from 'ant-design-vue';
import 'ant-design-vue/lib/table/style/css';
Vue.component(Table.name, Table);

import {Divider} from 'ant-design-vue';
import 'ant-design-vue/lib/divider/style/css';
Vue.component(Divider.name, Divider);

import {Switch} from 'ant-design-vue';
import 'ant-design-vue/lib/switch/style/css';
Vue.component(Switch.name, Switch);

import {Select} from 'ant-design-vue';
import 'ant-design-vue/lib/select/style/css';
Vue.component(Select.name, Select);
Vue.component(Select.Option.name, Select.Option);

import {Skeleton} from 'ant-design-vue';
import 'ant-design-vue/lib/skeleton/style/css';
Vue.component(Skeleton.name, Skeleton);

import {Popover} from 'ant-design-vue';
import 'ant-design-vue/lib/popover/style/css';
Vue.component(Popover.name, Popover);

import {List} from 'ant-design-vue';
import 'ant-design-vue/lib/list/style/css';
Vue.component(List.name, List);
Vue.component(List.Item.name, List.Item);
Vue.component(List.Item.Meta.name, List.Item.Meta);

import {Progress} from 'ant-design-vue';
import 'ant-design-vue/lib/progress/style/css';
Vue.component(Progress.name, Progress);

import {Row} from 'ant-design-vue';
import 'ant-design-vue/lib/row/style/css';
Vue.component(Row.name, Row);

import {Col} from 'ant-design-vue';
import 'ant-design-vue/lib/col/style/css';
Vue.component(Col.name, Col);

import {Drawer} from 'ant-design-vue';
import 'ant-design-vue/lib/drawer/style/css';
Vue.component(Drawer.name, Drawer);

import {Statistic} from 'ant-design-vue';
import 'ant-design-vue/lib/statistic/style/css';
Vue.component(Statistic.name, Statistic);

import {Timeline} from 'ant-design-vue';
import 'ant-design-vue/lib/timeline/style/css';
Vue.component(Timeline.name, Timeline);
Vue.component(Timeline.Item.name, Timeline.Item);

import {Dropdown} from 'ant-design-vue';
import 'ant-design-vue/lib/dropdown/style/css';
Vue.component(Dropdown.name, Dropdown);
Vue.component(Dropdown.Button.name, Dropdown.Button);

import {Menu} from 'ant-design-vue';
import 'ant-design-vue/lib/menu/style/css';
import 'ant-design-vue/lib/style/css';
Vue.component(Menu.name, Menu);
Vue.component(Menu.Item.name, Menu.Item);
Vue.component(Menu.SubMenu.name, Menu.SubMenu);

import {Spin} from 'ant-design-vue';
import 'ant-design-vue/lib/spin/style/css';
Vue.component(Spin.name, Spin);

import {DatePicker} from 'ant-design-vue';
import 'ant-design-vue/lib/date-picker/style/css';
Vue.component(DatePicker.name, DatePicker);
Vue.component(DatePicker.RangePicker.name, DatePicker.RangePicker);