import 'core-js/stable'
import Vue from 'vue'
// import Antd from 'ant-design-vue';
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import { i18n, $i18n } from './plugins/i18n'
import './plugins/flashmessage'
import './plugins/moment'
import './plugins/promisebtn'
import './plugins/numerals'
import './plugins/driver'
import './plugins/tawk'
import './plugins/calendar'
import './plugins/slideout-panel'
import './plugins/antd'

import firebaseMessaging from './firebase'

// import 'ant-design-vue/dist/antd.css';

// not required. Styles for built-in spinner
//import 'vue-promise-btn/dist/vue-promise-btn.css'


Vue.config.performance = true
Vue.use(CoreuiVue)
// Vue.use(Antd);
Vue.prototype.$log = console.log.bind(console)
Vue.prototype.$messaging = firebaseMessaging
export const bus = new Vue();


new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  i18n,
  components: {
    App
  },
  mounted() {
    const titleTag = document.querySelector('title');
    if (titleTag) {
      titleTag.text = $i18n('SiteTitle');
    }
  }
})
