import Vue from 'vue';
import axios from '@/plugins/axios.js';

import * as types from './types'

export default {
  [types.SECURITY_AUTH] (state, keycloakAuth) {
    console.log(' ----------------------- keycloakAuth', keycloakAuth)
    state.auth = keycloakAuth
    if(keycloakAuth && keycloakAuth.token ) {
      axios.defaults.headers.common = { 'Authorization': 'Bearer ' + keycloakAuth.token, 'realm': keycloakAuth.realm }

      try {
        Vue.prototype.$Tawk.$updateChatUser({
          name: keycloakAuth.tokenParsed.email,
          email: keycloakAuth.tokenParsed.email,
          hash: keycloakAuth.tokenParsed.email
        });

        /* document.tidioIdentify = { */
          /* distinct_id: keycloakAuth.tokenParsed.email, // Unique user ID in your system */
          /* email: keycloakAuth.tokenParsed.email, // User email */
          /* name: keycloakAuth.tokenParsed.email, // Visitor name */
          /* city: "", // City */
          /* country: "" // Country */
        /* }; */
      } catch (error) {
        console.log(error)
      }
    }
  }
}
