import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import App from '@/App.vue'
import security from '@/rockt-vuejs-keycloak'
import { languages } from '@/plugins/i18n'


// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

// Users
const UserInfo = () => import('@/views/user/Info')
const UserMember = () => import('@/views/user/Member')

const MemberPlans = () => import('@/views/member/Plans')
const MemberCharge = () => import('@/views/member/Charge')
const MemberPaySuccess = () => import('@/views/member/PaySuccess')
const MemberSubscriptionModify = () => import('@/views/member/SubscriptionModify')
const UpgradeDomains = () => import('@/views/member/Domains')
// const User = () => import('@/views/users/User')


// Views
const DomainList = () => import('@/views/domain/List')
const DomainRules = () => import('@/views/domain/Rules')
// const DomainGroupAdd = () => import('@/views/domain/Add')

// const DomainGroupDetail = () => import('@/views/domain/Detail')
const TrafficStatistics = () => import('@/views/domain/Traffic')
const ManageIP = () => import('@/views/domain/ManageIP')
const DomainImport = () => import('@/views/domain/Import')

// Views
const CertIndex = () => import('@/views/cert/Index')
//const DomainGroupDetail = () => import('@/views/domain/Detail')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')
const Unauthorized = () => import('@/views/pages/Unauthorized')
const PageTest = () => import('@/views/pages/Test')

const DnsIndex = () => import('@/views/dns/Index')


// // Users
// const Users = () => import('@/views/users/Users')
// const User = () => import('@/views/users/User')


// Transaction
const TransactionBills = () => import('@/views/transaction/BillList')
const TransactionInvoices = () => import('@/views/transaction/InvoiceList')
const TransactionCheckout = () => import('@/views/transaction/Checkout')

// Attack
const Attack = () => import('@/views/attack/Attack')

// Tickets
const Tickets = () => import('@/views/tickets/Tickets')

Vue.use(Router)

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

router.beforeEach((to, from, next) => {
  if (to.fullPath.indexOf("&error") > 0) {
    document.location.href = document.location.origin
    return
  }
  if(to.fullPath.indexOf("&state") > 0) {
    next(to.fullPath.substr(0, to.fullPath.indexOf("&state")))
    return
  }
  const auth = store.state.security.auth
  if (to.matched.some(route => route.meta.requiresAuth)) {
    if (auth == null || !auth.authenticated) {
      security.init(next, to.meta.roles)
    }
    else {
      if (to.meta.roles) {
        if (security.roles(to.meta.roles[0])) {
          next()
        }
        else {
          next({ name: 'Unauthorized' })
        }
      }
      else {
        next()
      }
    }
  }
  else {
    next()
  }
})

router.afterEach((to) => {
  Vue.prototype.$Tawk.$isInit &&
    window.Tawk_API.toggleVisibility(to.meta.showChat);
});

export default router


function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/domain/list',
      name: 'Home',
      component: TheContainer,

      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'user',
          meta: {
            label: 'User', requiresAuth: true, roles: ['user']
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'member',
              meta: {
                label: 'User Member',
                requiresAuth: true
              },
              name: 'UserMember',
              component: UserMember
            },
            {
              path: 'info',
              meta: {
                label: 'User Infomation'
              },
              name: 'UserInfo',
              component: UserInfo,
            }
          ]
        },
        {
          path: 'member',
          meta: { label: 'Member', requiresAuth: false }, //#
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'plans',
              meta: {
                label: 'Member Plans',
                requiresAuth: true,
                showChat: true
              },
              name: 'MemberPlans',
              component: MemberPlans
            },
            {
              path: 'domains',
              meta: {
                label: 'Upgrade Domains',
                requiresAuth: true,
                showChat: true
              },
              name: 'UpgradeDomains',
              component: UpgradeDomains
            },            
            {
              path: 'subscription/:id',
              meta: {
                label: 'Member Subscription Modify',
                requiresAuth: true,
                showChat: false
              },
              name: 'MemberSubscriptionModify',
              component: MemberSubscriptionModify
            },
            {
              path: 'charge',
              meta: {
                label: 'Member Charge',
                requiresAuth: false
              },
              name: 'MemberCharge',
              component: MemberCharge
            },
            {
              path: 'pay_success',
              meta: {
                label: 'Pay success',
                requiresAuth: true
              },
              name: 'MemberPaySuccess',
              component: MemberPaySuccess
            },

          ]
        },
        {
          path: 'domain',
          meta: { label: 'Domain', requiresAuth: true, roles: ['user']  },
          redirect: '/domain/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'list',
              meta: {
                label: 'Domain List',
                showChat: true
              },
              name: 'DomainList',
              component: DomainList,
            },
            {
              path: 'list0',
              redirect: 'list',
            },
            {
              path: 'rules',
              meta: {
                label: 'Domain Rules',
                showChat: true
              },
              name: 'DomainRules',
              component: DomainRules
            },            
            // {
            //   path: 'add',
            //   meta: {
            //     label: 'Add Group'
            //   },
            //   name: 'DomainGroupAdd',
            //   component: DomainGroupAdd
            // },
            // {
            //   path: ':id',
            //   meta: {
            //     label: 'Domain Group Detail'
            //   },
            //   name: 'DomainGroupDetail',
            //   component: DomainGroupDetail
            // },
            {
              path: '/traffic-statistics',
              meta: {
                label: 'Traffic Statistics'
              },
              name: 'TrafficStatistics',
              component: TrafficStatistics
            },
            {
              path: '/manage-ip',
              meta: {
                label: 'Manage IP'
              },
              name: 'ManageIP',
              component: ManageIP
            },
            {
              path: 'import',
              meta: {
                label: 'Domain Import'
              },
              name: 'DomainImport',
              component: DomainImport
            },
          ]
        },
        {
          path: 'cert',
          meta: { label: 'Cert', requiresAuth: true, roles: ['user']  },
          redirect: '/cert/index',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'index',
              meta: {
                label: 'Cert Index',
                showChat: true
              },
              name: 'CertIndex',
              component: CertIndex
            },
          ]
        },
        {
          path: 'transaction',
          meta: { label: 'Transaction', requiresAuth: true  },
          redirect: '/transaction/index',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'checkout',
              meta: {
                label: 'Transaction Checkout',
                requiresAuth: true,
                showChat: false
              },
              name: 'TransactionCheckout',
              component: TransactionCheckout
            },
            {
              path: 'bills',
              meta: {
                label: 'Transaction Bills',
                requiresAuth: true,
                showChat: false,
              },
              name: 'TransactionBills',
              component: TransactionBills
            },
            {
              path: 'invoices',
              meta: {
                label: 'Transaction Invoices',
                requiresAuth: true,
                showChat: false,
              },
              name: 'TransactionInvoices',
              component: TransactionInvoices
            }
          ]
        },

        {
          path: 'dns',
          meta: { label: 'DNS', requiresAuth: true, roles: ['user']  },
          redirect: '/dns/index',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'index',
              meta: {
                label: 'DNS Index',
                showChat: true
              },
              name: 'DNSIndex',
              component: DnsIndex
            }
            // {
            //   path: ':id',
            //   meta: {
            //     label: 'Domain Group Detail'
            //   },
            //   name: 'DomainGroupDetail',
            //   component: DomainGroupDetail
            // }
          ]
        },
        {
          path: 'tickets',
          meta: { label: 'Tickets', requiresAuth: true, roles: ['user'] },
          redirect: '/tickets/index',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'index',
              meta: {
                label: 'Tickets Index',
                showChat: true,
              },
              name: 'TicketsIndex',
              component: Tickets,
            },
          ]
        },
        {
          path: 'attack',
          meta: {label: 'Attack', requiresAuth: true, roles: ['user']},
          redirect: '/attack/index',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'index',
              meta: {
                label: 'Attack',
                showChat: true,
                roles: ['view-attack']
              },
              name: 'AttackIndex',
              component: Attack
            },
          ]
        }
      ]
    },

    { path: '/unauthorized',
      name: 'Unauthorized',
      component: Unauthorized
    }, // Unauthorized
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'test',
          name: 'PageTest',
          component: PageTest
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        },
        { path: 'unauthorized',
          name: 'Unauthorized2',
          component: Unauthorized
        } // Unauthorized
      ]
    },
    {
      path: '/:lang',
      component: App,
      beforeEnter(to, from, next) {
        let lang = to.params.lang
        if (languages.includes(lang)) {
          if (store.state.locale !== lang) {
            store.dispatch('changeLocale', lang)
          }
          return next()
        }
        return next({ path: '/' })
      }
    }
  ]
}

