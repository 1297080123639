import {
  cibAlipay,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackOverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex,
  cibWechat,
  cibTrainerroad,
  cibSparkpost,
  cibDashlane,
  cibIcloud,
  cibOpsgenie,
  cibBuzzfeed,
  cibEsea,
  cibEthereum,
  cibSkype,
  cibTelegram,
  cibTencentQq
} from '@coreui/icons'
import {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
} from '@coreui/icons'
import {
  cilAccountLogout,
  cilArrowRight,
  cilAvTimer,
  cilBan,
  cilBasket,
  cilBell,
  cilCalculator,
  cilCalendar,
  cilClock,
  cilCloudDownload,
  cilChart,
  cilChartLine,
  cilChartPie,
  cilCheck,
  cilCheckAlt,
  cilSwapVertical,
  cilChevronDoubleUp,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCheckCircle,
  cilCommentSquare,
  cilCursor,
  cilDrop,
  cilNotes,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilEuro,
  cilExternalLink,
  cilEyedropper,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilInfo,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilLibrary,
  cilLocationPin,
  cilLockLocked,
  cilMediaPause,
  cilMediaPlay,
  cilMediaRecord,
  cilMagnifyingGlass,
  cilMoon,
  cilObjectGroup,
  cilOptions,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPlaylistAdd,
  cilPlus,
  cilTrash,
  cilPuzzle,
  cilReload,
  cilSettings,
  cilShieldAlt,
  cilSignalCellular4,
  cilSmile,
  cilSpeech,
  cilSpeedometer,
  cilStar,
  cilTask,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilXCircle,
  cilWarning,
  cilCloudUpload,
  cilBrowser,
  cilFilter,
  cilArrowCircleRight,
  cilSave
} from '@coreui/icons'


export const iconsSet = Object.assign(
  {},
  {
    cilAccountLogout,
    cilArrowRight,
    cilAvTimer,
    cilBan,
    cilBasket,
    cilBell,
    cilCalculator,
    cilCalendar,
    cilClock,
    cilCloudDownload,
    cilChart,
    cilChartLine,
    cilChartPie,
    cilCheck,
    cilCheckAlt,
    cilSwapVertical,
    cilChevronDoubleUp,
    cilChevronBottom,
    cilChevronLeft,
    cilChevronRight,
    cilChevronTop,
    cilCheckCircle,
    cilCommentSquare,
    cilCursor,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilExternalLink,
    cilEyedropper,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilInfo,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilLibrary,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMediaPause,
    cilMediaPlay,
    cilMediaRecord,
    cilMoon,
    cilObjectGroup,
    cilOptions,
    cilPlaylistAdd,
    cilPencil,
    cilPeople,
    cilPhone,
    cilPlus,
    cilTrash,
    cilPuzzle,
    cilReload,
    cilSettings,
    cilShieldAlt,
    cilSignalCellular4,
    cilSmile,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilTask,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilXCircle,
    cilWarning,
    cilCloudUpload,
    cilBrowser,
    cilFilter,
    cilArrowCircleRight,
    cilNotes,
    cilSave
  },
  {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl
  },
  {
    cibAlipay,
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibStackOverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibStripe,
    cibPaypal,
    cibGooglePay,
    cibWechat,
    cibCcAmex,
    cibTrainerroad,
    cibSparkpost,
    cibDashlane,
    cibIcloud,
    cibOpsgenie,
    cibBuzzfeed,
    cibEsea,
    cibEthereum,
    cibSkype,
    cibTelegram,
    cibTencentQq
  }
)
