import store from '@/store'

export default (role) => {
  var keycloakAuth = store.getters.SECURITY_AUTH
  if (keycloakAuth.authenticated) {
    if (role == 'view-attack') {
      return keycloakAuth.hasResourceRole(role, 'account')
    } else {
      return keycloakAuth.hasResourceRole(role)
    }
  }
  else {
    return false
  }
}
