import Vue from 'vue'
import axios from 'axios'
import {$i18n} from './i18n'
import {GATEWAY_CONNECTION_ERROR, QUOTA_REACHED, SUBSCRIPTION_NOT_FOUND} from '@/error_codes';

let rootDomain = location.hostname.split('.').reverse().splice(0, 2).reverse().join('.')

const $axios = axios.create({
  baseURL: (process.env.VUE_APP_API_URL || process.env.BASE_URL || 'http://localhost:3000').replace("suyun" + "cdn.com", rootDomain),
  devURL: process.env.VUE_APP_DEV_URL || process.env.BASE_DEV_URL || 'http://localhost:3000',
  // fixme: reduce timeout for non-development environment after optimization to routes api
  timeout: process.env.NODE_ENV === 'development' ? 600000 : 1200000
});

// $axios.interceptors.request.use(config => {
//     if (!config.url) {
//         return config;
//     }
//
//     const currentUrl = new URL(config.url, config.dev ? config.devURL : config.baseURL);
//     // parse pathName to implement variables
//   // console.log(currentUrl.pathname);
//     Object.entries(config.urlParams || {}).forEach(([
//         k,
//         v,
//     ]) => {
//       console.log({k,v})
//       //{cname}
//         currentUrl.pathname = currentUrl.pathname.replace('{' + k + '}', encodeURIComponent(v)).replace('%7B' + k + '%7D', encodeURIComponent(v));
//     });
//     // console.log(currentUrl.pathname, config.urlParams, config)
//
//     // i18n Header
//     config.headers['Accept-Language'] = store.state.locale;
//
//     const authPart = currentUrl.username && currentUrl.password ? `${currentUrl.username}:${currentUrl.password}` : '';
//     return {
//         ...config,
//         baseURL: `${currentUrl.protocol}//${authPart}${currentUrl.host}`,
//         url: currentUrl.pathname,
//     };
// });

$axios.interceptors.response.use((response) => {
    return response
  },
  (error) => {
    if (error.response && error.response.data) {
      const errorData = error.response.data;
      const vue = new Vue();
      if (errorData.error_code === SUBSCRIPTION_NOT_FOUND) {
        vue.$message.error($i18n('ErrorOccurred') + ': ' + $i18n('message.SubscriptionNotFound'), 15);
      }
      if (errorData.error_code === GATEWAY_CONNECTION_ERROR) {
        vue.$message.error($i18n('ErrorOccurred') + ': ' + $i18n('message.GatewayConnectionError'), 15);
      }
      if (errorData.error_code === QUOTA_REACHED) {
        vue.$message.error($i18n('ErrorOccurred') + ': ' + $i18n('message.QuotaReached'), 5);
      }
    }

    let errors = []
    if (error.response) {
        if (error.response.data instanceof Object) {
            for (let key in error.response.data) {
                if( key == "success" && error.response.data[key] == false) {
                    continue;
                }
                errors.push(key + "." + error.response.data[key]);
            }
        } else if (error.response.data.message) {
            errors.push(error.response.data.message);
        } else {
            errors.push($i18n('message.request_error'));
        }
    } else {
      errors.push($i18n('message.network_error'));
    }

    return Promise.reject({error, errors});
  });

export default $axios;
