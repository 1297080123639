import axios from '@/plugins/axios';
import * as defaultAxios from 'axios';


export function createDomain(data) {
    return axios({
        method: "POST",
        url: "domain/routes/",
        data: data
    });
}
export function addwhitelistip(data) {
  return axios({
      method: "POST",
      url: "domain/whitelist/",
      data: data
  });
}
export function ipvalidation(data) {
  return axios({
      method: "POST",
      url: "domain/ip_validation/",
      data: data
  });
}
export function getDomains() {
  return axios({
    method: "GET",
    url: 'domain/routes/',
    data: {}
  });
}

export function getRoutes(params = {}) {
  return axios.get('domain/routes/', {
    params: params
  });
}


export function findDomains(payload = {}, params = {}) {
  return axios({
      method: "POST",
      url: "domain/find-domain/",
      params: params,
      data: payload
  });
}

export function getRules(params = {}) {
  return axios.get('domain/rules/', {
    params: params
  });
}

export function getCerts(params = {}) {
  return axios.get('domain/ssl/', {
    params: params
  });
}

export function getRouteCertificates(routeId) {
  return axios.get(`domain/routes/${routeId}/ssl-config/`);
}

export function deleteRouteCertificate(routeId, {certificateId}) {
  return axios.delete(`domain/routes/${routeId}/ssl-config/${certificateId}/`);
}

export function uploadDomainCertificate(routeId, {domain, cert, key}) {
  return axios.post(`domain/routes/${routeId}/ssl-config/`, {
    domain,
    cert,
    key,
  });
}

export function updateForceSslConfig(routeId, {forceHttps}) {
  return axios.patch(`domain/routes/${routeId}/ssl-config/`, {
    force_https: forceHttps
  });
}

export function getDomainPlugins(serviceId) {
  return axios.get(`domain/services/${serviceId}/plugins/`);
}

export function updateDomainPlugins(serviceId, {plugins}) {
  return axios.patch(`domain/services/${serviceId}/plugins/`, {
    plugins
  });
}

export function getDomain(domainName, perpage=10, exact_search=false) {
  let url;
  if (exact_search) {
    url = `domain/routes/?page=1&perpage=${perpage}&domain_name=${domainName}`;
  } else {
    url = `domain/routes/?page=1&perpage=${perpage}&q=${domainName}`;
  }
  return axios({
    method: "GET",
    url: url,
    data: {},
    urlParams: {
      domain: domainName
    }
  });
}

export function deactivateDomain(domainName, domainData = null) {
  const params = {
    method: "POST",
    url: `domain/{domain}/stop/`,
    urlParams: {
      domain: domainName
    }
  };
  if (domainData)
    params.data = domainData;

  return axios(params);
}

export function pauseDomain(domainName, isPaused = null) {
  const params = {
    method: "POST",
    url: `domain/pause_restore/`,
    data: {
        hosts: [domainName],
        paused: isPaused,
    },
  };
  return axios(params);
}

export function deleteDomain({routeId, cname}) {
  return axios.delete(`domain/routes/${routeId}/`, {
    data: {
        cname
    }
  })
}

export function deleteRule({ruleId, cname}) {
  return axios.delete(`domain/rules/${ruleId}/`, {
    data: {
        cname
    }
  })
}

export function generateDomainSshKey({routeId, domain}) {
  return axios({
    method: 'PATCH',
    url: `domain/routes/${routeId}/ssl-config/generate/`,
    data: {domain}
  });
}

export function resumeDomain(domainName, domainData) {
  const params = {
    method: "POST",
    url: `domain/{domain}/resume/`,
    urlParams: {
      domain: domainName
    }
  };
  if (domainData)
    params.data = domainData;

  return axios(params);
}

export function redeployDomain(domainName, domainData) {
  const params = {
    method: "POST",
    url: `domain/{domain}/redeploy/`,
    urlParams: {
      domain: domainName
    },
    timeout: 5000
  };
  if (domainData)
    params.data = domainData;

  return axios(params);
}

export function diagnoseDomain(route_id) {
  if (route_id) {
    return axios.post(
      `domain/checker/`,
      {route_id: route_id}
    );
  }
}

export function getDomainSubscription() {
  return axios.get('domain/configure/subscription/');
}

export function enableIPv6Subscription(ipv6) {
  return axios.post(
    `domain/configure/subscription/`,
    {ipv6: ipv6}
  );
}

export function getUserTasks() {
  return axios.get('domain/run/tasks/');
}

export function clearCacheTask(serviceIds) {
  const params = {
    method: "POST",
    url: `domain/run/tasks/`,
    data: {ids: serviceIds, task: 'CLEAR_CACHE'}
  }

  return axios(params);
}

export function enableForceHttpsTasks(routeIds) {
  const params = {
    method: "POST",
    url: `domain/run/tasks/`,
    data: {ids: routeIds, task: 'FORCE_HTTPS', options: {enabled: true}}
  }

  return axios(params)
}

export function disableForceHttpsTasks(routeIds) {
  const params = {
    method: "POST",
    url: `domain/run/tasks/`,
    data: {ids: routeIds, task: 'FORCE_HTTPS', options: {enabled: false}}
  }

  return axios(params)
}

export function enableHttpsTasks(routeIds) {
  const params = {
    method: "POST",
    url: `domain/run/tasks/`,
    data: {ids: routeIds, task: 'ENABLE_HTTPS', options: {}}
  }

  return axios(params)
}

/* PLAN */

export function listPlan() {
  return axios.get('plan/');
}

export function payPlan(plan, coin, paymentType, additional_domain_number, additional_traffic) {
  return axios.post(
    'plan/subscription/pay/',
    {
      plan: plan,
      currency2: coin,
      payment_type: paymentType,
      additional_domain_number: additional_domain_number,
      additional_traffic: additional_traffic,
    },
  )
}

export function payDomain(coin, paymentType, additional_domain_number, additional_traffic) {
  return axios.post(
    'plan/domain/pay/',
    {
      currency2: coin,
      payment_type: paymentType,
      additional_domain_number: additional_domain_number,
      additional_traffic: additional_traffic,
    },
  )
}

export function renewPlan(subscriptionID, coin, paymentType) {
  return axios.post(
    `plan/subscription/${subscriptionID}/renew/`,
    {
      currency2: coin,
      payment_type: paymentType
    },
  )
}

// export function payTrialPlan(subscriptionID, coin, paymentType) {
//   return axios.post(
//     `plan/subscription/trial/${subscriptionID}/pay/`,
//     {
//       currency2: coin,
//       payment_type: paymentType
//     },
//   )
// }

export function subscriptionPlan() {
  return axios
    .get(`plan/subscription/`, {
      params: {},
      urlParams: {}
    });
}

export function fcmRegister(currentToken) {
  return axios.post(
    'domain/fcm-register/',
    {
      token: currentToken
    },
  )
}

export function sendNotification(batch_type) {
  return axios.post(
    'domain/notify/',
    {
      type: batch_type
    },
  )
}

export function getUserNotification() {
  return axios.get('domain/notify/');
}

export function domainConfig() {
  return axios.get('domain/config/');
}


export function getThreatsThisMonth() {
  return axios.get('attack/history/month/');
}

export function getThreatsToday() {
  return axios.get('attack/history/today/');
}

export function getThreatsResponseStatus() {
  return axios.get('attack/history/response-status/');
}

export function getThreatsFakewallThreat() {
  return axios.get('attack/history/fakewall-threat/');
}

export function getThreatsMitigationAttack() {
  return axios.get('attack/history/mitigation-attack/');
}
export function getClientIP() {
  return defaultAxios.get('https://api.ipify.org/?format=json');
};
export function getUserAuditLogs() {
  return axios.get('domain/log-entry/');
}

export function getDomainVerifyUpstreamProtocol(upstreams) {
    return axios.get(`domain/verify-upstream-protocol/?upstreams=${upstreams}`);
}
