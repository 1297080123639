import Keycloak from 'keycloak-js'
import store from '@/store'

let domain = document.domain.replace(".dev.", ".");

// let keycloakAuth = new Keycloak(config)
let keycloakAuth = new Keycloak('/statics/keycloak_'+ domain +'.json')

export default (next, roles) => {
  let initOption = { onLoad: 'check-sso', silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html' }
  // let initOption = {onLoad: 'login-required'}
  keycloakAuth.init(initOption)
    .then((authenticated) => {
      if (!authenticated) {
        keycloakAuth.login()
        return;
      }
      store.dispatch('authLogin', keycloakAuth)
        .then(async () => {
          await store.dispatch('initStore');
          
          if (roles) {
            if (keycloakAuth.hasResourceRole(roles[0])) {
              next()
            }
            else {
              next({ name: 'Unauthorized' })
            }
          }
          else {
            next()
          }
          setInterval(function () {
            keycloakAuth.updateToken(70)
              .then((refreshed) => {
                if (refreshed) {
                  store.dispatch('authLogin', keycloakAuth)
                }
                else {
                  console.log('Token not refreshed, valid for ' + Math.round(keycloakAuth.tokenParsed.exp + keycloakAuth.timeSkew - new Date().getTime() / 1000) + ' seconds')
                }
              }).catch(function () {
                console.error('Failed to refresh token')
                keycloakAuth.login()
              })
          }, 60000)
          
        });
    })
    .catch(() => {
      console.log('failed to login')
    })
}
